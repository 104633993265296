<i18n>
ru:
  cart: Корзина
ua:
  cart: Кошик
us:
  cart: Cart
</i18n>

<template>
  <header-parts-basket-button-page @click="callForSidebar" />

  <ClientOnly>
    <Teleport to="#__nuxt">
      <div
        v-if="sidebarOpen"
        :id="`${uid}-backdrop`"
        class="v-slide-menu-backdrop"
        @click="callForSidebar"
        style="opacity: 0"
      />
      <div
        :id="`${uid}-cart`"
        class="v-sidebar"
        data-test-id="slide-small-basket"
        style="right: -500px"
      >
        <cart-rhudaur-sidebar-content :close-function="callForSidebar" />
      </div>
    </Teleport>
  </ClientOnly>
</template>

<script setup lang="ts">
import gsap from 'gsap'

const restaurantStore = useRestaurantStore()

const { hasActivePopup } = storeToRefs(usePopupStore())
const router = useRouter()

const uid = useId()

const sidebarOpen = ref<boolean>(false)

router.beforeEach(() => (sidebarOpen.value ? callForSidebar() : undefined))

async function callForSidebar(): Promise<void> {
  if (sidebarOpen.value) {
    gsap.to(`#${uid}-backdrop`, {
      duration: 0.3,
      ease: 'sine.inOut',
      onComplete: () => {
        document.body.classList.remove('v-open-overflow--manual')
        sidebarOpen.value = false
      },
      opacity: 0
    })

    gsap.to(`#${uid}-cart`, {
      duration: 0.3,
      ease: 'sine.inOut',
      right: -500
    })
  } else {
    sidebarOpen.value = true
    document.body.classList.add('v-open-overflow--manual')

    await nextTick()
    gsap.to(`#${uid}-backdrop`, {
      duration: 0.3,
      ease: 'sine.inOut',
      opacity: 1
    })

    gsap.to(`#${uid}-cart`, {
      duration: 0.3,
      ease: 'sine.inOut',
      right: 0
    })
  }
}

const { stop } = watch(
  () => sidebarOpen.value,
  (newValue) => {
    if (newValue) {
      restaurantStore.Metrics?.sendMetricCartView(true)
      stop()
    }
  }
)

watch(hasActivePopup, (newValue) => {
  if (newValue && sidebarOpen.value) {
    callForSidebar()
  }
})
</script>

<style lang="scss">
@use '~/assets/mixins';
@use '~/assets/variables';

.v-sidebar {
  background: variables.$Background;
  color: variables.$BodyTextColor;
  position: fixed;
  z-index: 9999;
  top: 0;
  height: 100vh;
  box-shadow: variables.$FloatShadow;

  width: 500px;

  @include mixins.lg {
    width: 400px;
  }

  @include mixins.md {
    width: 370px;
  }

  @include mixins.sm {
    width: 500px;
  }
}
</style>
