<i18n>
ru:
  birthdayGift: "Подарок на День Рождения!"
  choose: Выбрать
  edit: Изменить
ua:
  birthdayGift: "Подарунок на День Народження!"
  choose: Вибрати
  edit: Редагувати
us:
  birthdayGift: "Birthday Gift!"
  choose: Choose
  edit: Edit
</i18n>

<template>
  <div
    class="v-birthday-gifts v-d-flex v-pointer v-align-items-center"
    @click="callForPopup"
  >
    <div class="v-birthday-gifts__text">
      <span
        class="v-typography-title-3"
        v-html="translate('birthdayGifts.birthdayGift')"
      />
    </div>
    <div class="v-birthday-gifts__button">
      <span
        class="v-typography-title-6"
        v-html="translate(`birthdayGifts.${!!selectedGift ? 'edit' : 'choose'}`)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { CartItem, PresentInfo } from '~types/clientStore'

const clientStore = useClientStore()
const popupStore = usePopupStore()

const { translate } = useI18nSanitized()

const selectedGift = computed<CartItem | null>(() => {
  const gifts = clientStore.ClientState.data?.BirthDayPresents?.Presents ?? null

  if (gifts === null) {
    return null
  }

  return (
    clientStore.ClientState?.data?.Cart?.Content?.find((item: CartItem) => {
      return (
        gifts && gifts.some((gift: PresentInfo) => gift.ProductID === item.ProductID)
      )
    }) ?? null
  )
})

async function callForPopup(): Promise<void> {
  await popupStore.openPopup({
    popupClosable: true,
    popupName: 'cartBirthdayGiftPopup'
  })
}
</script>

<style lang="scss">
@use 'assets/mixins';
@use 'assets/variables';

.v-birthday-gifts {
  border-radius: variables.$BorderRadius;
  background: variables.$SecondaryBackgroundColor;
  color: variables.$SecondaryColor;
  padding: 0.855rem;
  gap: 0.855rem;

  @include mixins.trans;

  &:hover {
    background: variables.$SecondaryBackgroundColorHover;
  }

  &__text {
    flex: 1;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__button {
    flex: 0 0 6.785rem;
    width: 6.785rem;
    border-radius: variables.$BorderRadiusButton;
    background: variables.$SecondaryColor;
    color: variables.$SecondaryBackgroundColor;
    padding: 0.57rem 0;
    display: flex;
    justify-content: center;
  }
}
</style>
