<i18n>
ru:
  cannotWriteOff: Не получится списать
  edit: Изменить
  totalBonuses: 'Всего бонусов: {points} Б'
  writeOffPoints: Списать
ua:
  cannotWriteOff: Не вийде списати
  edit: Редагувати
  totalBonuses: 'Всього бонусів: {points} Б'
  writeOffPoints: Списати
us:
  cannotWriteOff: Cannot write off
  edit: Edit
  totalBonuses: 'Total bonuses: {points} points'
  writeOffPoints: Write off
</i18n>

<template>
  <div class="v-partial-points v-d-flex v-flex-column">
    <div class="v-d-flex v-flex-row v-justify-content-between v-mb-xs">
      <div
        class="v-d-flex v-align-items-center v-pointer"
        @click="callForPopup"
      >
        <span
          class="v-typography-title-5 v-mr-xxs"
          v-html="translate('partialPoints.writeOffPoints')"
        />
        <common-currency
          class="v-typography-title-5"
          show-points
          :amount="pointsWriteOff"
        />
      </div>
      <div>
        <ui-switch
          :label="translate('partialPoints.writeOffPoints')"
          v-model:checked="enabled"
          :disabled="!allowed"
        >
          &nbsp;
        </ui-switch>
      </div>
    </div>
    <div
      v-if="allowed"
      class="v-typography-text-7"
      :class="{
        'v-mb-xs': enabled
      }"
      v-html="
        translate('partialPoints.totalBonuses', {
          points: clientStore.ClientState.data?.PartialPayState?.TotalPoints ?? 0
        })
      "
    />
    <div
      class="v-d-flex v-align-items-center"
      v-else
    >
      <ui-icon
        disable-hover
        class-name="v-mr-xxs"
        color="mono400"
        :size="16"
        name="error"
      />
      <span
        class="v-typography-text-6 v-color-mono-400"
        v-html="translate('partialPoints.cannotWriteOff')"
      />
    </div>
    <div v-if="allowed">
      <transition
        mode="out-in"
        name="slidedown"
      >
        <ui-button
          v-if="enabled"
          :size="24"
          color="primary"
          type="text-with-button"
          width="content"
          :label="translate('partialPoints.edit')"
          :click-event="callForPopup"
        />
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
const accountStore = useAccountStore()
const clientStore = useClientStore()
const popupStore = usePopupStore()

const { translate } = useI18nSanitized()

const allowed = computed<boolean>(
  () => clientStore.ClientState.data?.PartialPayState?.PartialPayAllowed ?? false
)

const enabled = computed<boolean>({
  get() {
    return (
      (clientStore.ClientState?.data?.Cart?.ToPayWithPartialPayPoints?.Amount ?? 0) >
      0
    )
  },
  set(value) {
    if (value) callForPopup()
    else accountStore.updatePartialPay(0)
  }
})

const pointsWriteOff = computed<number>(() =>
  enabled.value
    ? (clientStore.ClientState?.data?.Cart?.ToPayWithPartialPayPoints?.Amount ?? 0)
    : (clientStore.ClientState.data?.PartialPayState?.MaxBoundary ?? 0)
)

async function callForPopup(): Promise<void> {
  popupStore.openPopup({
    popupClosable: true,
    popupName: 'partialPayPopup'
  })
}
</script>
