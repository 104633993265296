<i18n>
ru:
  defaultTitle: Внимание
ua:
  defaultTitle: Увага
us:
  defaultTitle: Attention
</i18n>

<template>
  <TransitionGroup
    name="list"
    tag="ul"
    class="v-cart-sidebar-messages-wrapper"
  >
    <li
      v-for="(message, index) in messages"
      :key="`cart-message-${message.Type}-${index}`"
      class="v-cart-sidebar-message v-d-flex v-flex-column"
      :class="{
        'v-cart-sidebar-message__custom':
          message.Type === MessageType.DefaultCustom ||
          message.Type === MessageType.ImportantCustom ||
          message.Type === MessageType.ImportantNotBlockingCustom
      }"
    >
      <div
        class="v-d-flex v-justify-content-between v-align-items-center v-w-100"
        role="alert"
      >
        <div
          class="v-cart-sidebar-message__text v-typography-title-8"
          v-html="translate('messages.defaultTitle')"
        />
        <div
          v-if="
            message.Type === MessageType.Default ||
            message.Type === MessageType.DefaultCustom
          "
          class="v-cart-sidebar-message__icon v-pointer"
        >
          <ui-icon
            :size="16"
            color="mono400"
            class-name="v-pointer"
            name="close"
          />
        </div>
      </div>
      <div
        class="v-cart-sidebar-message__text v-typography-text-6"
        v-html="sanitize(message.Text)"
      />
    </li>
  </TransitionGroup>
</template>

<script setup lang="ts">
import type { MessagesV2 } from '~types/clientStore'

import { MessageType } from '~api/consts'

const clientStore = useClientStore()
const { sanitize, translate } = useI18nSanitized()

const messages = computed<MessagesV2[]>(() => {
  return clientStore.ClientState?.data?.MessagesV2 ?? []
})
</script>

<style lang="scss">
@use '~/assets/variables';

.v-cart-sidebar-messages-wrapper {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .v-cart-sidebar-message {
    border-radius: variables.$BorderRadius;
    background: variables.$BodyBackgroundDarken15;
    color: variables.$BodyTextColor;
    padding: 0.85rem;

    display: flex;
    align-items: center;

    &__custom {
      background: variables.$OfferCartMessageTextBackgroundColor;
      color: variables.$OfferCartMessageTextColor;
    }
  }
}
</style>
